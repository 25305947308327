<template>
  <div>
    <!-- address section start -->
    <section class="contact-page policy-content section-b-space">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-justify">
            <h2 class="text-left mb-3 mt-4">CANCELLATION & REFUND POLICIES</h2>
            <h5>POSSIBILITIES FOR RETURNING THE PRODUCTS / ITEMS</h5>
            <p>
              When the Customer receives defective / damaged / tampered /
              different product then the ordered one/ one of the accessories
              missing and etc.
            </p>
            <p>
              For the above Customer has to meet our CANCELLATION & REFUND
              POLICIES mentioned here under
            </p>
            <h5>GENERAL POLICY - CONDITIONS FOR RETURN</h5>
            <p>
              For cancellation and returning the product sold through the App /
              Webpage, customers are required to comply with the below mentioned
              conditions:
            </p>
            <p>
              Return of the Product / Item must be according to the TEKA Return
              Policy laid with the Product Category.
            </p>
            <p>
              Customer has to notify TEKA on receipt of defective / damaged /
              tampered / different product then the ordered one/ one of the
              accessories missing and etc., by email /contacting customer care /
              SMS through Registered Mobile Number.
            </p>
            <p>
              Customer must return the Products unused, in their original
              packaging along with the original price tags, labels, packing,
              barcodes, user manual, warranty card and invoices, accessories,
              freebies and original boxes and as received. If any product is
              returned without the essentials/ as received, the product shall
              not be accepted for return and cancellation will not be processed.
            </p>
            <p>
              Customer while returning the product/item he/she should ensure to
              send in the same package as received from TEKA / Vendor. The
              return must be in compliance with the return policy of the seller
              or the manufacturer of the product whichever is applicable.
            </p>
            <p>
              The products/ items returned are subject to verification by the
              sellers selling through TEKA in order to determine the legitimacy
              of the complaint received.
            </p>
            <p>
              If the customers have chosen off-line order option for delivery of
              the order and wish to return the product, customer should return
              the product to the same store from where customer collected the
              order. The customer should return the product in original and
              unused condition along with all original price tags, labels,
              packing, barcodes, user manuals, warranty card and invoices,
              accessories, freebies and boxes as received. Store reserves the
              right to accept the product.
            </p>
            <h5>Non-Returnable Products/Items</h5>
            <p>
              There are products/items of non-Returnable nature (which have no
              shelf-life) and our Policies are applicable only Returnable
              Product/Items
            </p>
            <p>
              Food Products, Single time usable products/ Disposable products.
            </p>
            <p>Innerwear, lingerie, socks, clothing freebies and swimsuits.</p>
            <p>Perfumes, Personal and Beauty care Products / Items.</p>
            <p>
              Products / Items which are not covered above, and which have no
              shelf-life are also part of this category.
            </p>
            <h5>CANCELLATION BY TEKA</h5>
            <p>
              TEKA reserve the right to cancel an order. Reasons for
              cancellation are as under:
            </p>
            <p>Non-Availability of the Product/Item with the Seller/Vendor.</p>
            <p>In-case of any fraud identified by TEKA fraud avoidance team.</p>
            <p>
              Invalid address / wrong address mentioned by the Customer in the
              Order Form.
            </p>
            <p>Malpractices used to place the order.</p>
            <p>Bulk order placed for reselling/commercial purpose.</p>
            <p>
              Non-availability of the Customer to deliver the Order up to three
              (3) attempts.
            </p>
            <p>If the bank rejects the EMI offer.</p>
            <p>Technical errors or issues.</p>
            <p>
              Pricing or specifications on any product as is shown on the App
              due to typographical error or incorrect information inadvertently
              provided by the seller or some technical glitch, resulting into
              incorrect pricing or specifications.
            </p>
            <p>
              Once the order is settled cancellation or refund is not possible
              because TEKA calculates and distributes reward points, discounts,
              cash backs and others special incentives for that order based on
              the final confirmation from the customer and it would be
              impossible to reverse the changes made. However, you can exchange
              for another products which same price is or higher than the
              original price but based on the availability of the product, TEKA
              reserve the acceptance of the such exchange.
            </p>
            <p>
              Notwithstanding, anything contained hereunder, TEKA reserves the
              right, at its sole discretion, to refuse or cancel any order for
              any reason whatsoever without any further liability. On such
              cancellation, customer will be sent a notification email or SMS at
              the email address or mobile number provided to TEKA.
            </p>
            <p>
              Customer agree to release and indemnify TEKA, its officers and
              representatives from any cost, damage, liability or any of the
              actions in case of cancellation of Customer Order by TEKA or
              seller. For the acts / implication beyond TEKA control (Natural
              Disaster / Calamities / Strikes etc.)
            </p>

            <h5>Cancellation by Customer</h5>
            <p>
              Customer can cancel the Product / Item before the product has been
              shipped for delivery to customer. No cancellation is permitted
              thereafter.
            </p>
            <p>
              The customer can cancel an order, or part of an order where
              multiple products have been ordered. This can be done either by
              calling or emailing to TEKA Customer Care Team or in the
              Customer's account on the App through 'my order account'. The
              customer should state the reason for such cancellation. TEKA will
              process the request for cancellation as per the policy in force.
            </p>
            <p>
              If an order has been successfully cancelled, customer will be sent
              a confirmation email or SMS at the email address or mobile number
              provided to TEKA.
            </p>
            <p>
              TEKA reserves the right to accept or reject requests for order
              cancellations for any reason whatsoever. The customer agrees not
              to hold TEKA liable for any rejection of order cancellations.
            </p>
            <p>
              The customer will receive a refund for the cancelled orders that
              have been accepted in accordance with the Refund Policy of TEKA.
            </p>
            <h5>REFUND POLICY</h5>
            <p>
              Refunds are provided for cancellation / return of products,
              subject to the eligible cancellation as per TEKA Return Policies.
            </p>
            <p>
              Refund is made for the full amount of the order or part order
              which passes successfully cancellation/refund process.
            </p>
            <p>
              For return requests, the refund payment will not be made and
              allows customer to exchange with another product. If no other
              product is of like to customer, then the amount will be retained
              in the customer's wallet for the next time purchases. The
              difference product cost along with any other costs will be
              adjusted and charged as applicable if the product cost is higher
              than the initially purchased.
            </p>
            <p>
              If the customer receives any wrongful refund or payment, the
              customer has to transfer such amount back to TEKA, (our Account
              Details are mentioned here under) within 2 days of customer
              receiving such amount.
            </p>
            <p>
              VEDHAANK DIGITAL PRIVATE LIMITED <br />
              BANK A/C NO. 409001402330<br />
              IFSC CODE: RATN0000376
            </p>
            <h5>Time Period for Refunds</h5>
            <p>
              TEKA will take One Week for processing the Refund after review of
              the product returned and further depends on various banking and
              payment channels.
            </p>
            <p>
              Interest charged by the bank providing the EMI Scheme till the
              time the request for return or cancellation is raised will not be
              refunded.
            </p>
            <p>
              TEKA is not responsible for any errors or delays in refund due to
              banks or third-party service provider errors or delays.
            </p>
            <h5>Mode of Refund</h5>
            <p>
              The mode of refund of payments cannot be changed at any stage as
              the refund amount is transferred to the customer's source account.
              Refunds are paid back to the source of payment.
            </p>
            <p>
              Refund for payments made by modes other than Cash on Delivery,
              which fail when processed back to source may be refunded by
              National Electronic Funds Transfer (“NEFT”) to the customer's bank
              account.
            </p>
            <p>
              The customer will need to update the bank account number and IFSC
              code to enable us to process a refund to customer's account.
              Refunds cannot be processed to third-party accounts, i.e., the
              name on Your TEKA account should match with the name of the bank
              account holder provided for refund via NEFT. A refund initiation
              confirmation by email or SMS or mobile number provided to TEKA
              will be sent to customer.
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- Section ends -->
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
};
</script>
<style scoped>
.policy-content .eb-useing-gumtree {
  position: relative;
}
.policy-content h3 {
  color: #533333;
  font-weight: 600;
}
.policy-content .eb-device-terms li {
  font-size: 16px;
  font-weight: 600;
  color: #212121;
  position: relative;
  margin-bottom: 10px;
  display: list-item;
  list-style: circle;
  margin-left: 1.4%;
}
.policy-content .eb-useing-gumtree li {
  font-size: 16px;
  font-weight: 600;
  color: #212121;
  position: relative;
  padding-left: 35px;
  margin-bottom: 10px;
  display: list-item;
  list-style: none;
}
.policy-content .eb-useing-gumtree li i {
  position: absolute;
  top: 3px;
  left: 0;
  color: #828282;
  font-size: 14px;
  background-color: #d7eef8;
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50%;
  text-align: center;
}
.flaticon-checked:before {
  content: "✓";
}
</style>
